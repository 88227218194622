<template>
  <div class="animated fadeIn">
  <b-row>
    <b-col cols="12" lg="12">
      <b-card class="w-100" no-header>
        <template slot="header">
          <span class="mt-2">{{ this.details.name }} Product</span>
          <b-button @click="goBack" class="btn-sm pull-right">Back</b-button>
        </template>

        <b-card no-body id="data">

          <b-tabs card>

            <b-tab v-show="details" title="Product Details">

              <table class="table" v-if="details">
                <tbody>
                    <tr v-for="(v, k, index) in details" :key="index" v-if="k !== 'id' && k !== 'limits' && k !== 'waitingperiod' && k !== 'claimlimits'">
                      <th>{{ k|capitalize }}</th>
                      <td colspan="2" v-html="v">{{ v }}</td>
                    </tr>
                </tbody>
              </table>

            </b-tab>

            <b-tab v-show="details.limits.length > 0" title="Product Limits">

              <table class="table" v-if="details">

                <tr>
                  <th>Section</th>
                  <th>Type</th>
                  <th>Description</th>
                  <th>Pet Type</th>
                  <th>Age Limit</th>
                  <th>Limit</th>
                </tr>

                <tr v-for="(limit, indexJ) in details.limits" :key="indexJ">
                  <template v-if="limit.limit > 0">
                    <td>{{limit.section}}</td>
                    <td>{{limit.limit_type}}</td>
                    <td>{{limit.type}}<br/>{{limit.payout_type}}</td>
                    <td>{{limit.pet_type}}</td>
                    <td v-if="limit.age_limit">{{limit.age_limit_type}} {{limit.age_limit}}</td>
                    <td v-else>&nbsp;</td>
                    <td>&pound;{{limit.limit}}</td>
                  </template>
                  <template v-else>
                    <td class="bg-danger text-dark" style="opacity: 0.5;">{{limit.section}}</td>
                    <td class="bg-danger text-dark" style="opacity: 0.5;">{{limit.limit_type}}</td>
                    <td class="bg-danger text-dark" style="opacity: 0.5;">{{limit.type}}<br/>{{limit.payout_type}}</td>
                    <td class="bg-danger text-dark" style="opacity: 0.5;">{{limit.pet_type}}</td>
                    <td class="bg-danger text-dark" style="opacity: 0.5;" v-if="limit.age_limit">{{limit.age_limit_type}} {{limit.age_limit}}</td>
                    <td class="bg-danger text-dark" style="opacity: 0.5;" v-else>&nbsp;</td>
                    <td class="bg-danger text-dark" style="opacity: 0.5;">N/A</td>
                  </template>
                </tr>
              </table>

            </b-tab>

            <b-tab v-show="details.waitingperiod.length > 0" title="Product Waiting Periods">

              <table class="table" v-if="details">
                <tbody>
                    <tr>
                      <th>Period</th>
                      <th>Day(s)</th>
                    </tr>
                    <tr v-for="(v, k, index) in details.waitingperiod" :key="index">
                      <td>{{v.period_type}}</td>
                      <td>{{v.period}}</td>
                    </tr>
                </tbody>
              </table>

            </b-tab>

            <b-tab v-show="details.claimlimits.length > 0" title="Skip Manual Assessment Claim Limits">

              <table class="table" v-if="details">
                <tbody>
                    <tr>
                      <th>Claim Type</th>
                      <th>Claim Sub Type</th>
                      <th>Claim Limit</th>
                      <th>Tolerance</th>
                    </tr>
                    <tr v-for="(v, k, index) in details.claimlimits" :key="index">
                      <td>{{v.claim_type_pretty}}</td>
                      <td>{{v.claim_sub_type_pretty}}</td>
                      <td>&pound;{{v.claim_amount}}</td>
                      <td>&pound;{{v.tolerance}}</td>
                    </tr>
                </tbody>
              </table>

            </b-tab>

            <!--<b-tab v-show="details.documents.length > 0" title="Product Claim Documents">

              <table class="table" v-if="details">
                <tbody>
                    <tr>
                      <th>Claim Type</th>
                      <th>Claim Sub Type</th>
                      <th>Claim Limit</th>
                      <th>Tolerance</th>
                    </tr>
                    <tr v-for="(v, k, index) in details.documents" :key="index">
                      {{v}}
                      <td>{{v.claim_type_pretty}}</td>
                      <td>{{v.claim_sub_type_pretty}}</td>
                      <td>&pound;{{v.claim_amount}}</td>
                      <td>&pound;{{v.tolerance}}</td>
                    </tr>
                </tbody>
              </table>

            </b-tab>-->

          </b-tabs>

        </b-card>

        <template slot="footer">
          <b-button @click="goBack" class="float-left">Back</b-button>
          <!--<b-button v-show="hasFullAccess" :to="{ name: 'ProductEdit', params: { product: this.$route.params.product, id: this.$route.params.id } }" variant="outline-dark" class="float-right">Edit</b-button>-->
        </template>
      </b-card>
    </b-col>
  </b-row>

  </div>

</template>

<script>
  import axios from '../../../shared/axios/auth-no-loader'
  import Id from "bootstrap-vue/src/mixins/id";

  export default {
    name: 'Product',
    components: {Id},
    props: {
      caption: {
        type: String,
        default: 'Product Details'
      },
    },
    data: () => {
      return {
        details: {
         value: "",
         percent: "",
         csid: "",
        }
      }
    },
    methods: {
      goBack() {
        this.$router.go(-1)
      },
      getData() {

        let url = '/products/' + this.$route.params.id +  '/';

        axios.get(url).then(
          response => {
            this.details = response.data
          }
        ).catch(error => {
            // Unauthorised
            if (error.response.status === 401) {
              this.$store.dispatch('logout');
            }
            console.log('email setting error');
            console.log(error.response)
          })
        return this.details
      }
    },
    computed: {
      hasFullAccess() {

        let access = ['full_admin',];

        let allowed = false;

        access.forEach(item => {
          if (this.$store.getters.getUserPermissions.includes(item)) {
            allowed = true
          }
        })

        return allowed

      },
    },
    mounted() {
      this.getData()
    },
    watch: {
      $route(to, from) {
        // react to route changes...
        this.$router.replace(to.fullPath).catch(error => {
          console.log(error)
        }).then(
          this.getData()
        );

      }
    }
  }
</script>

<style>

  #data .card-header {
    padding-top: 1.5rem;
  }
</style>
